.resource-box{

  // text-align:center;
  margin-top:15px;
  transition: all .3s linear;
  display: flex;
  background: #F4F6F9;
  cursor: pointer;
  img{
    margin:0 auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.resource-image{
  position:relative;
  display: block;
  min-width: 100px;
//  max-width: 100px;
  width:100px;
//  -webkit-box-flex: 1;
//  flex: 1 1 0%;
  border:5px solid #F4F6F9;
}

.resource-info{

  padding:15px;
  transition: all .3s linear;

}


.resource-box:hover{
  box-shadow: 0 20px 20px 0 rgba(9,1,34,0.06);
  background: #fff;

}

.resource-date{
  display: block;
}

.resource-title{
  display: block;
  font-size: 16px;
  color: @title-color;
 // text-overflow: ellipsis;
//  overflow: hidden;
//  white-space: nowrap;
  a{
    color: @title-color;

  }
}

.resource-detail{
  display:block;
  font-size: 14px;
  line-height: 140%;
 // white-space: nowrap;
 // text-overflow: ellipsis;
 // overflow: hidden;
}


.latest-resources{
  margin:0px 0 70px 0;
}

.resource-date{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  text-align:center;
  background:@mycolor-red;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
