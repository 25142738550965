.button{
  display: inline-block;
  padding:15px 55px;
  color: #fff;
  text-align:center;
  font-weight: bold;
  background: @brand-color2;
  border-radius: 5px;

  &:hover{
    color: #fff;
    background: darken(@brand-color2, 30%);
    text-decoration: none;
  }
}