.home-corner{
  position:absolute;
  top:0;
  right:0;
  z-index:1;
  display:block;
  width:620px;
  height:670px;
  background: url('../images/home-corner.png') no-repeat;
}

.header{
  margin-top:30px;
  z-index:100;
  display:block;
  position: relative;
  margin-bottom:30px;
}

.home-header{
  padding-bottom:100px;
  background: url('../images/blue-circles.png') center bottom no-repeat;

  .container{
    z-index:2;
    display: block;
    position:relative;
  }
}


a.logo{
  float:left;
}

.home-info{
  margin-top: 120px;

  h1{
    font-size: 54px;
    margin-bottom:40px;
  }
}

@media (max-width: @screen-md-min) {

  .home-info{
    margin-top:30px;
    h1{
      font-size:40px;
      margin-bottom:20px;
    }
  }
  .home-corner{
    width:200px;
  }
}

@media (max-width: @screen-xs-min) {
  .home-corner{
   display:none;
  }
}