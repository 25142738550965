.footer {
  background: @brand-color;
  color: #fff;
  >.container{
    padding: 30px 0 20px;
  }

  hr{
    opacity:.1;
  }

  &::before {
    content: " ";
    display: block;
    // position:absolute;
    width: 100%;
    height: 72px;
    background: url('../images/wave-top.png') repeat-x;
    //background-size: cover;
  }
}


.footer-newsletter-wrapper{
  margin:0 auto;
  max-width: 600px;
  text-align: center;
  h3{
    color: #fff;
    font-weight: 400;
    margin-bottom:20px;
  }

  .contact-form-email{
    min-width: 300px;
    border-radius: 20px;
  }

  .btn{
    background:@brand-color3;
    color: #fff;
    border-radius: 20px;
    margin-left:-5px;
  }


}

.footer-inner{
  margin-top:50px;
  .footer-inner-about{
    font-size:14px;
    line-height: 140%;
  }
}

.copyright{
  margin-left:15px;
  font-size:13px;
 // text-align: center;
  a{color:#fff;}
}