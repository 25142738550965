.course-box{
  background: #F4F6F9;
  // text-align:center;
  margin-top:15px;
  transition: all .3s linear;
  cursor: pointer;

  img{
    margin:0 auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.course-info{
  background: #F4F6F9;
  padding:35px;
  transition: all .3s linear;
}


.course-box:hover{
  box-shadow: 0 20px 20px 0 rgba(9,1,34,0.06);

  .course-info{
    background: #fff;
  }
}

.course-date{
  display: block;
}

.course-title{
  display: block;
  font-size: 20px;
  color: @title-color;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  a{
    color: @title-color;

  }
}

.course-detail{
  display:block;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.latest-courses{
  margin:100px 0 70px 0;

}


.course-show-header{

  background: rgb(148,98,250);
  background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);

  p,h1,h2,h3{
    color: #fff;
  }
}

.course-show-header-inner{
  text-align:center;
  padding:50px 0 70px;
  max-width: 800px;
  margin:0 auto;

  h1{
    font-size:50px;
    font-weight: 400;
  }

  .curse-show-intro{
    color: #fff;
    font-size: 15px;
    line-height: 140%;
    font-weight: 300;
  }

  .curse-show-image{
    max-width:200px;
    max-height: 200px;
    border-radius:50%;
    margin:0 auto;
  }
}


.course-show-details{
  max-width:800px;
  padding:50px 0;
}

.section-count{
  font-weight: bold;
  width:30px;
  display: inline-block;
}

.episode-list, .subvideo-list{
  list-style:none;
  padding:0;
  margin:0;
}

.curse-section{
  background: @mycolor-grey;
  width:100%;
  border-radius: 10px;
  padding: 25px;
  h2{
    padding:0;
    margin:0;
    font-size:20px;
  }
}

.course-in-list{
  padding: 5px 10px;
  display: flex;

  img{
    display: block;
    width:50px;
    height: 50px;
    border-radius: 50%;
    margin-right:10px;
  }

  h2{
    a{
      color: @mycolor-black;
    }
    font-size: 24px;
    padding:0;
    margin:15px 0 0 0;
    font-weight: 700;
  }

  .course-in-list-info{
    -webkit-box-flex: 1;
    flex: 1 1 0%;
  }
}


.curse-video-runtime{
  font-size:13px;
  color: #78909c;
  font-weight: bold;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right:10px;

  i{
    margin-right:5px;
  }
}

.course-video-intro{
  font-size:14px;
  line-height: 120%;
}


.course-video-subinfo{
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;

}
.curse-video-type{
  display: inline-block;
  padding:0 10px;
  background: @mycolor-blue;
  color: #fff;
  font-weight: 700;
  border-radius: 9999px;
  font-size:11px;
  line-height: 160%;
  text-transform: uppercase;

  -webkit-box-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
}


.videotype-free{
  background: @mycolor-green;
}

.videotype-crm{
  background: @mycolor-blue;
}

.videotype-mls{
  background: @mycolor-dark-red;
}

.course-video{
  border-radius: 10px;
  padding: 5px 25px;
  display: flex;
  margin-top:10px;

  transition: all .3s linear;
  cursor: pointer;

  &:hover{
    background: @mycolor-grey;
  }
  .count-circle{
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    background: #e5e5e5;
    text-align: center;
    margin-right: 40px;
    font-size:18px;
    font-weight: 300;
    color: #22292f;
  }
}

.course-video-details{
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  h4 {
    padding:0;
    margin:0;
    a {
      color: @mycolor-black;
    }
  }
}


.is-condensed{
  .episode-list{
    margin:5px;
  }
  h2{
    font-size:16px;
  }
  h4{
    font-size:15px;
  }
  .curse-section{
    padding:15px;
  }

  .course-video{
    padding: 5px 10px;


  }
  .course-video-intro{
    display: none;
  }

  .count-circle{
    width:40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-right:10px;
  }

  .active .count-circle{
    background: @brand-color;
    color: #fff;
  }
}

.course-in-list-break{
  display:block;
  height: 1px;
  border:none;
  border-top: 1px solid #f1f5f8;
  margin-top: 8px;
  margin-bottom: 8px;
}

#video-show-prev-button,#video-show-next-button{
  position:absolute;
  top: 50%;
  margin-top:-32px;
  height: 64px;
  background-color:@brand-color3;
  line-height: 64px;
  right:0;
  padding:0 10px;
  i{
    font-size:40px;
    line-height: 64px;
  }
}

#video-show-prev-button{
  right:auto;
  left:0;
}

.prev-next-buttons{
 opacity:0;
  transition: opacity 0.3s ease 0s;
}

#imomaster-video:hover{
  .prev-next-buttons{
    opacity:1;
  }
}

#play-next-window{

  &.active{
    display:block;
  }

  &.course-complete{
    color:@mycolor-green;
    font-size: 18px;
  //  font-weight: bold;
  }

  height:64px;
  position:absolute;
  top:50%;
  margin-top:-32px;
  right:50px;
  padding:15px;
  background-color:@brand-color3;
  color: #fff;
  display:none;
  transition: all .3s linear;

  button{
    display:inline-block;
    padding:5px 10px;
    background: @mycolor-blue;
    color: #fff;
    font-weight: 700;
    border-radius: 9999px;
    font-size:12px;
    line-height: 160%;
    text-transform: uppercase;
    border:none;
    margin-left:15px;
  }
}

#next-episode-in{
  font-weight: bold;
  font-size:20px;
}


