.footer-menu{
  list-style:none;
  margin-top:10px;
  margin-left:0;
  padding-left:0;

  li{
    display: inline-block;

    a{
      font-weight: 500;
      display:inline-block;
      padding:5px 15px;
      color: #fff;
      opacity:.7;
//    font-size:14px;
      &:hover{
        opacity: 1;
        text-decoration: none;
      }
    }
  }
}

#mainmenu{
  float:left;
  list-style: none;
  margin-top:10px;
  margin-left:50px;

  li{
    float:left;

    a{
      color:@mycolor-black;
      display:block;
      padding: 5px 10px;
      margin:0 5px;
      font-weight: 600;

      &:hover{
        opacity:.8;
      }

      &.active{
        color: @brand-color;
      }
    }

    a.main-blue{
      background:@brand-color2;
      color: #fff;
      border-radius: 5px;
      padding: 5px 25px;

      &:hover{
        background: darken(@brand-color2, 30%);
      }
    }
  }


}


@media (max-width: @screen-md) {
 #mainmenu{
   float:none;
   width:100%;
   margin:0;
   padding:0;
   background: #fff;
   li{
     display:block;
     float:none;
   }
 }
}