html{
  height: 100%;
//  height: 100%;
//  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: @font-main;
  background-color: @body-bg-color;

  line-height: 160%;
  margin:0;
  padding:0;
  color: @body-color;
  font-weight: 300;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

 // min-height: 100%;
 // padding-bottom: 6rem;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#content-wrap{
  flex: 1 0 auto;
}
#footer {
  flex-shrink: 0;
}

h1,h2,h3,h4,h5{
  //font-weight: bold;
  color: #1F2C39;
  font-weight: 400;
}

h1{
  font-size: 50px;
}

a:hover{
  text-decoration: none;
}



h2{
  font-size: 30px;
  font-weight: 500;
}

.div-title{
  background: @mycolor-grey;
  text-transform: uppercase;
  padding: 25px 15px;
  margin-bottom:20px;
  border-radius: 10px;
}

h3.div-title{
  font-size: 18px;
}

.module-title{
  text-align:center;
  margin-bottom:25px;
 }

.margintop10{
  margin-top:10px !important;
}

.margintop20{
  margin-top:20px !important;
}


.gradient-html-one{
  background: rgb(148,98,250);
  background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);
  height: 100%;

  body{
    background: none;
  }
}

.html-flex{
  height: 100%;
  body {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;

  }
}

