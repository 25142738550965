.divider{
  margin:30px 0;
  padding:30px 0;
}

.divider-blue{
  background: @brand-color;
}

.breadcrumbs{
  background: rgb(148,98,250);
  background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);
  padding:10px;
  color: #fff;
}

.divider-bluegradient{
    background: rgb(148,98,250);
    background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);

  h1{
    color: #fff;
  }
}

.divider-wave{
  &::before{
    content:" ";
    display:block;
   // position:absolute;
    width:100%;
    height:72px;
    background: url('../images/wave-top.png') repeat-x;
    //background-size: cover;
  }

  &::after{
    content:" ";
    display:block;
   // position:absolute;
    width:100%;
    height:72px;
    background: url('../images/wave-bottom.png') repeat-x;
    //background-size: cover;
  }
}

.divider-testimonials{
  margin:30px 0;
  padding:30px 0;
}