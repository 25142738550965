.fancy-form{
  input[type="text"], textarea, input[type="password"], input[type="email"], input[type="tel"], input[type="text"], textarea, input[type="password"], input[type="email"] {
    font: 400 15px/60px "Poppins", sans-serif;
    color: #222d39;
    height: 60px;
    border-radius: 4px;
    background-color: white;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    width: 100%;
    border: 1px solid #fff;
    padding-left: 30px;
  }

  input[type="text"]:focus, textarea:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="tel"]:focus {
    border-color: #f2effc;
    -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
    box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
    outline: none;
  }

  textarea{
    height: 150px;
  }
}