.course-video-show-header{

//  background: rgb(148,98,250);
//  background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);


  background: @mycolor-darkgrey;
//  p,h1,h2,h3{
//    color: #fff;
//  }

  display:flex;
  justify-content:  center;
  -webkit-box-pack: center;
}

.course-video-show-video-list{
  width:330px;
  overflow: auto;
  margin-right:15px;
  display:block;
  background: #fff;
}

.course-video-show-video-player{
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  border-radius:10px;
  max-width: 100vh;
}

.video-show-details{
  max-width:800px;
  padding:50px 0;

}

.course-video.active{
  .radial-progress {


    .circle{
      display: none;
    }

    .inset {
      background: @brand-color;
      .percentage {
        color: #fff;
      }
    }
  }
}

#video-list-show{
  float:right;

  padding:5px 15px;
  border-radius: 5px;
  background: #fff;
  color: @mycolor-black;
  font-size:12px;
  display:none;
}

#video-show-wrap{
  iframe{
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
  }
}

#imomaster-video .warning{
  padding:15px 15px;
  color: #fff;
  background: @mycolor-red;
}

@media(max-width: @screen-md){
  .course-video-show-video-list {
    display: none;
  }

  .video-list-visible{
    display:block;
  }

  #video-list-show{
    display:block;
  }
  .breadcrumb{
    float:left;
  }
}