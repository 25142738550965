/*
* Common
*/
html {
  height: 100%;
}
body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: #FBFBFD;
  line-height: 160%;
  margin: 0;
  padding: 0;
  color: #667293;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#content-wrap {
  flex: 1 0 auto;
}
#footer {
  flex-shrink: 0;
}
h1,
h2,
h3,
h4,
h5 {
  color: #1F2C39;
  font-weight: 400;
}
h1 {
  font-size: 50px;
}
a:hover {
  text-decoration: none;
}
h2 {
  font-size: 30px;
  font-weight: 500;
}
.div-title {
  background: #f6f6f6;
  text-transform: uppercase;
  padding: 25px 15px;
  margin-bottom: 20px;
  border-radius: 10px;
}
h3.div-title {
  font-size: 18px;
}
.module-title {
  text-align: center;
  margin-bottom: 25px;
}
.margintop10 {
  margin-top: 10px !important;
}
.margintop20 {
  margin-top: 20px !important;
}
.gradient-html-one {
  background: #9462fa;
  background: linear-gradient(169deg, #9462fa 0%, #7231f8 100%);
  height: 100%;
}
.gradient-html-one body {
  background: none;
}
.html-flex {
  height: 100%;
}
.html-flex body {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.text-align-center {
  text-align: center !important;
}
.home-corner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: block;
  width: 620px;
  height: 670px;
  background: url('../images/home-corner.png') no-repeat;
}
.header {
  margin-top: 30px;
  z-index: 100;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.home-header {
  padding-bottom: 100px;
  background: url('../images/blue-circles.png') center bottom no-repeat;
}
.home-header .container {
  z-index: 2;
  display: block;
  position: relative;
}
a.logo {
  float: left;
}
.home-info {
  margin-top: 120px;
}
.home-info h1 {
  font-size: 54px;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .home-info {
    margin-top: 30px;
  }
  .home-info h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .home-corner {
    width: 200px;
  }
}
@media (max-width: 480px) {
  .home-corner {
    display: none;
  }
}
.button {
  display: inline-block;
  padding: 15px 55px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  background: #5E2CED;
  border-radius: 5px;
}
.button:hover {
  color: #fff;
  background: #260a76;
  text-decoration: none;
}
.course-box {
  background: #F4F6F9;
  margin-top: 15px;
  transition: all .3s linear;
  cursor: pointer;
}
.course-box img {
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.course-info {
  background: #F4F6F9;
  padding: 35px;
  transition: all .3s linear;
}
.course-box:hover {
  box-shadow: 0 20px 20px 0 rgba(9, 1, 34, 0.06);
}
.course-box:hover .course-info {
  background: #fff;
}
.course-date {
  display: block;
}
.course-title {
  display: block;
  font-size: 20px;
  color: #1F2C39;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.course-title a {
  color: #1F2C39;
}
.course-detail {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.latest-courses {
  margin: 100px 0 70px 0;
}
.course-show-header {
  background: #9462fa;
  background: linear-gradient(169deg, #9462fa 0%, #7231f8 100%);
}
.course-show-header p,
.course-show-header h1,
.course-show-header h2,
.course-show-header h3 {
  color: #fff;
}
.course-show-header-inner {
  text-align: center;
  padding: 50px 0 70px;
  max-width: 800px;
  margin: 0 auto;
}
.course-show-header-inner h1 {
  font-size: 50px;
  font-weight: 400;
}
.course-show-header-inner .curse-show-intro {
  color: #fff;
  font-size: 15px;
  line-height: 140%;
  font-weight: 300;
}
.course-show-header-inner .curse-show-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  margin: 0 auto;
}
.course-show-details {
  max-width: 800px;
  padding: 50px 0;
}
.section-count {
  font-weight: bold;
  width: 30px;
  display: inline-block;
}
.episode-list,
.subvideo-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.curse-section {
  background: #f6f6f6;
  width: 100%;
  border-radius: 10px;
  padding: 25px;
}
.curse-section h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
.course-in-list {
  padding: 5px 10px;
  display: flex;
}
.course-in-list img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.course-in-list h2 {
  font-size: 24px;
  padding: 0;
  margin: 15px 0 0 0;
  font-weight: 700;
}
.course-in-list h2 a {
  color: #1F2C39;
}
.course-in-list .course-in-list-info {
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
.curse-video-runtime {
  font-size: 13px;
  color: #78909c;
  font-weight: bold;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 10px;
}
.curse-video-runtime i {
  margin-right: 5px;
}
.course-video-intro {
  font-size: 14px;
  line-height: 120%;
}
.course-video-subinfo {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
}
.curse-video-type {
  display: inline-block;
  padding: 0 10px;
  background: #328af1;
  color: #fff;
  font-weight: 700;
  border-radius: 9999px;
  font-size: 11px;
  line-height: 160%;
  text-transform: uppercase;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
}
.videotype-free {
  background: #11C26D;
}
.videotype-crm {
  background: #328af1;
}
.videotype-mls {
  background: #CC005A;
}
.course-video {
  border-radius: 10px;
  padding: 5px 25px;
  display: flex;
  margin-top: 10px;
  transition: all .3s linear;
  cursor: pointer;
}
.course-video:hover {
  background: #f6f6f6;
}
.course-video .count-circle {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #e5e5e5;
  text-align: center;
  margin-right: 40px;
  font-size: 18px;
  font-weight: 300;
  color: #22292f;
}
.course-video-details {
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
.course-video-details h4 {
  padding: 0;
  margin: 0;
}
.course-video-details h4 a {
  color: #1F2C39;
}
.is-condensed .episode-list {
  margin: 5px;
}
.is-condensed h2 {
  font-size: 16px;
}
.is-condensed h4 {
  font-size: 15px;
}
.is-condensed .curse-section {
  padding: 15px;
}
.is-condensed .course-video {
  padding: 5px 10px;
}
.is-condensed .course-video-intro {
  display: none;
}
.is-condensed .count-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.is-condensed .active .count-circle {
  background: #7231F8;
  color: #fff;
}
.course-in-list-break {
  display: block;
  height: 1px;
  border: none;
  border-top: 1px solid #f1f5f8;
  margin-top: 8px;
  margin-bottom: 8px;
}
#video-show-prev-button,
#video-show-next-button {
  position: absolute;
  top: 50%;
  margin-top: -32px;
  height: 64px;
  background-color: #1F2C39;
  line-height: 64px;
  right: 0;
  padding: 0 10px;
}
#video-show-prev-button i,
#video-show-next-button i {
  font-size: 40px;
  line-height: 64px;
}
#video-show-prev-button {
  right: auto;
  left: 0;
}
.prev-next-buttons {
  opacity: 0;
  transition: opacity 0.3s ease 0s;
}
#imomaster-video:hover .prev-next-buttons {
  opacity: 1;
}
#play-next-window {
  height: 64px;
  position: absolute;
  top: 50%;
  margin-top: -32px;
  right: 50px;
  padding: 15px;
  background-color: #1F2C39;
  color: #fff;
  display: none;
  transition: all .3s linear;
}
#play-next-window.active {
  display: block;
}
#play-next-window.course-complete {
  color: #11C26D;
  font-size: 18px;
}
#play-next-window button {
  display: inline-block;
  padding: 5px 10px;
  background: #328af1;
  color: #fff;
  font-weight: 700;
  border-radius: 9999px;
  font-size: 12px;
  line-height: 160%;
  text-transform: uppercase;
  border: none;
  margin-left: 15px;
}
#next-episode-in {
  font-weight: bold;
  font-size: 20px;
}
.divider {
  margin: 30px 0;
  padding: 30px 0;
}
.divider-blue {
  background: #7231F8;
}
.breadcrumbs {
  background: #9462fa;
  background: linear-gradient(169deg, #9462fa 0%, #7231f8 100%);
  padding: 10px;
  color: #fff;
}
.divider-bluegradient {
  background: #9462fa;
  background: linear-gradient(169deg, #9462fa 0%, #7231f8 100%);
}
.divider-bluegradient h1 {
  color: #fff;
}
.divider-wave::before {
  content: " ";
  display: block;
  width: 100%;
  height: 72px;
  background: url('../images/wave-top.png') repeat-x;
}
.divider-wave::after {
  content: " ";
  display: block;
  width: 100%;
  height: 72px;
  background: url('../images/wave-bottom.png') repeat-x;
}
.divider-testimonials {
  margin: 30px 0;
  padding: 30px 0;
}
.wave-info {
  color: #fff;
  padding-left: 70px;
  position: relative;
  margin-top: 60px;
}
.wave-info::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0 px;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 12px solid #9D6EFD;
}
.wave-info h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}
.home-wave {
  padding: 30px 0;
}
.testimonials-slider {
  max-width: 770px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  padding: 50px;
}
.testimonials-slider .author_description {
  margin-top: 18px;
  margin-bottom: 40px;
}
.testimonials-slider h4 {
  font-size: 18px;
  font-weight: 500;
  color: #222d39;
}
.testimonials-slider h6 {
  font-weight: 400;
  font-size: 14px;
  color: #959cb1;
}
.author_img img {
  width: 125px;
  height: 125px;
  border-radius: 9999px;
}
.footer {
  background: #7231F8;
  color: #fff;
}
.footer > .container {
  padding: 30px 0 20px;
}
.footer hr {
  opacity: .1;
}
.footer::before {
  content: " ";
  display: block;
  width: 100%;
  height: 72px;
  background: url('../images/wave-top.png') repeat-x;
}
.footer-newsletter-wrapper {
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
}
.footer-newsletter-wrapper h3 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 20px;
}
.footer-newsletter-wrapper .contact-form-email {
  min-width: 300px;
  border-radius: 20px;
}
.footer-newsletter-wrapper .btn {
  background: #1F2C39;
  color: #fff;
  border-radius: 20px;
  margin-left: -5px;
}
.footer-inner {
  margin-top: 50px;
}
.footer-inner .footer-inner-about {
  font-size: 14px;
  line-height: 140%;
}
.copyright {
  margin-left: 15px;
  font-size: 13px;
}
.copyright a {
  color: #fff;
}
.course-video-show-header {
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
}
.course-video-show-video-list {
  width: 330px;
  overflow: auto;
  margin-right: 15px;
  display: block;
  background: #fff;
}
.course-video-show-video-player {
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  border-radius: 10px;
  max-width: 100vh;
}
.video-show-details {
  max-width: 800px;
  padding: 50px 0;
}
.course-video.active .radial-progress .circle {
  display: none;
}
.course-video.active .radial-progress .inset {
  background: #7231F8;
}
.course-video.active .radial-progress .inset .percentage {
  color: #fff;
}
#video-list-show {
  float: right;
  padding: 5px 15px;
  border-radius: 5px;
  background: #fff;
  color: #1F2C39;
  font-size: 12px;
  display: none;
}
#video-show-wrap iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
#imomaster-video .warning {
  padding: 15px 15px;
  color: #fff;
  background: #EB2F71;
}
@media (max-width: 992px) {
  .course-video-show-video-list {
    display: none;
  }
  .video-list-visible {
    display: block;
  }
  #video-list-show {
    display: block;
  }
  .breadcrumb {
    float: left;
  }
}
.radial-progress {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
}
.radial-progress .circle .mask,
.radial-progress .circle .fill,
.radial-progress .circle .shadow {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
}
.radial-progress .circle .mask,
.radial-progress .circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: -ms-transform 1s;
  transition: transform 1s;
  border-radius: 50%;
}
.radial-progress .circle .mask {
  clip: rect(0px, 40px, 40px, 20px);
}
.radial-progress .circle .mask .fill {
  clip: rect(0px, 20px, 40px, 0px);
  background-color: #7231F8;
}
.radial-progress .inset {
  width: 36px;
  height: 36px;
  position: absolute;
  margin-left: 2px;
  margin-top: 2px;
  background-color: #f6f6f6;
  border-radius: 50%;
}
.radial-progress .inset .percentage {
  width: 57px;
  position: absolute;
  top: 11px;
  left: -10.5px;
  line-height: 1;
  text-align: center;
  color: #000;
  font-weight: 800;
  font-size: 14px;
}
.radial-progress[data-progress="0"] .circle .mask.full,
.radial-progress[data-progress="0"] .circle .fill {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.radial-progress[data-progress="0"] .circle .fill.fix {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.radial-progress[data-progress="1"] .circle .mask.full,
.radial-progress[data-progress="1"] .circle .fill {
  -webkit-transform: rotate(1.8deg);
  -ms-transform: rotate(1.8deg);
  transform: rotate(1.8deg);
}
.radial-progress[data-progress="1"] .circle .fill.fix {
  -webkit-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.radial-progress[data-progress="2"] .circle .mask.full,
.radial-progress[data-progress="2"] .circle .fill {
  -webkit-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.radial-progress[data-progress="2"] .circle .fill.fix {
  -webkit-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.radial-progress[data-progress="3"] .circle .mask.full,
.radial-progress[data-progress="3"] .circle .fill {
  -webkit-transform: rotate(5.4deg);
  -ms-transform: rotate(5.4deg);
  transform: rotate(5.4deg);
}
.radial-progress[data-progress="3"] .circle .fill.fix {
  -webkit-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.radial-progress[data-progress="4"] .circle .mask.full,
.radial-progress[data-progress="4"] .circle .fill {
  -webkit-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.radial-progress[data-progress="4"] .circle .fill.fix {
  -webkit-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.radial-progress[data-progress="5"] .circle .mask.full,
.radial-progress[data-progress="5"] .circle .fill {
  -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg);
}
.radial-progress[data-progress="5"] .circle .fill.fix {
  -webkit-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  transform: rotate(18deg);
}
.radial-progress[data-progress="6"] .circle .mask.full,
.radial-progress[data-progress="6"] .circle .fill {
  -webkit-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.radial-progress[data-progress="6"] .circle .fill.fix {
  -webkit-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.radial-progress[data-progress="7"] .circle .mask.full,
.radial-progress[data-progress="7"] .circle .fill {
  -webkit-transform: rotate(12.6deg);
  -ms-transform: rotate(12.6deg);
  transform: rotate(12.6deg);
}
.radial-progress[data-progress="7"] .circle .fill.fix {
  -webkit-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.radial-progress[data-progress="8"] .circle .mask.full,
.radial-progress[data-progress="8"] .circle .fill {
  -webkit-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.radial-progress[data-progress="8"] .circle .fill.fix {
  -webkit-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.radial-progress[data-progress="9"] .circle .mask.full,
.radial-progress[data-progress="9"] .circle .fill {
  -webkit-transform: rotate(16.2deg);
  -ms-transform: rotate(16.2deg);
  transform: rotate(16.2deg);
}
.radial-progress[data-progress="9"] .circle .fill.fix {
  -webkit-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.radial-progress[data-progress="10"] .circle .mask.full,
.radial-progress[data-progress="10"] .circle .fill {
  -webkit-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  transform: rotate(18deg);
}
.radial-progress[data-progress="10"] .circle .fill.fix {
  -webkit-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  transform: rotate(36deg);
}
.radial-progress[data-progress="11"] .circle .mask.full,
.radial-progress[data-progress="11"] .circle .fill {
  -webkit-transform: rotate(19.8deg);
  -ms-transform: rotate(19.8deg);
  transform: rotate(19.8deg);
}
.radial-progress[data-progress="11"] .circle .fill.fix {
  -webkit-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.radial-progress[data-progress="12"] .circle .mask.full,
.radial-progress[data-progress="12"] .circle .fill {
  -webkit-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.radial-progress[data-progress="12"] .circle .fill.fix {
  -webkit-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.radial-progress[data-progress="13"] .circle .mask.full,
.radial-progress[data-progress="13"] .circle .fill {
  -webkit-transform: rotate(23.4deg);
  -ms-transform: rotate(23.4deg);
  transform: rotate(23.4deg);
}
.radial-progress[data-progress="13"] .circle .fill.fix {
  -webkit-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.radial-progress[data-progress="14"] .circle .mask.full,
.radial-progress[data-progress="14"] .circle .fill {
  -webkit-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.radial-progress[data-progress="14"] .circle .fill.fix {
  -webkit-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.radial-progress[data-progress="15"] .circle .mask.full,
.radial-progress[data-progress="15"] .circle .fill {
  -webkit-transform: rotate(27deg);
  -ms-transform: rotate(27deg);
  transform: rotate(27deg);
}
.radial-progress[data-progress="15"] .circle .fill.fix {
  -webkit-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  transform: rotate(54deg);
}
.radial-progress[data-progress="16"] .circle .mask.full,
.radial-progress[data-progress="16"] .circle .fill {
  -webkit-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.radial-progress[data-progress="16"] .circle .fill.fix {
  -webkit-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.radial-progress[data-progress="17"] .circle .mask.full,
.radial-progress[data-progress="17"] .circle .fill {
  -webkit-transform: rotate(30.6deg);
  -ms-transform: rotate(30.6deg);
  transform: rotate(30.6deg);
}
.radial-progress[data-progress="17"] .circle .fill.fix {
  -webkit-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.radial-progress[data-progress="18"] .circle .mask.full,
.radial-progress[data-progress="18"] .circle .fill {
  -webkit-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.radial-progress[data-progress="18"] .circle .fill.fix {
  -webkit-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.radial-progress[data-progress="19"] .circle .mask.full,
.radial-progress[data-progress="19"] .circle .fill {
  -webkit-transform: rotate(34.2deg);
  -ms-transform: rotate(34.2deg);
  transform: rotate(34.2deg);
}
.radial-progress[data-progress="19"] .circle .fill.fix {
  -webkit-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.radial-progress[data-progress="20"] .circle .mask.full,
.radial-progress[data-progress="20"] .circle .fill {
  -webkit-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  transform: rotate(36deg);
}
.radial-progress[data-progress="20"] .circle .fill.fix {
  -webkit-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  transform: rotate(72deg);
}
.radial-progress[data-progress="21"] .circle .mask.full,
.radial-progress[data-progress="21"] .circle .fill {
  -webkit-transform: rotate(37.8deg);
  -ms-transform: rotate(37.8deg);
  transform: rotate(37.8deg);
}
.radial-progress[data-progress="21"] .circle .fill.fix {
  -webkit-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.radial-progress[data-progress="22"] .circle .mask.full,
.radial-progress[data-progress="22"] .circle .fill {
  -webkit-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.radial-progress[data-progress="22"] .circle .fill.fix {
  -webkit-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.radial-progress[data-progress="23"] .circle .mask.full,
.radial-progress[data-progress="23"] .circle .fill {
  -webkit-transform: rotate(41.4deg);
  -ms-transform: rotate(41.4deg);
  transform: rotate(41.4deg);
}
.radial-progress[data-progress="23"] .circle .fill.fix {
  -webkit-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.radial-progress[data-progress="24"] .circle .mask.full,
.radial-progress[data-progress="24"] .circle .fill {
  -webkit-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.radial-progress[data-progress="24"] .circle .fill.fix {
  -webkit-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.radial-progress[data-progress="25"] .circle .mask.full,
.radial-progress[data-progress="25"] .circle .fill {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.radial-progress[data-progress="25"] .circle .fill.fix {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.radial-progress[data-progress="26"] .circle .mask.full,
.radial-progress[data-progress="26"] .circle .fill {
  -webkit-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.radial-progress[data-progress="26"] .circle .fill.fix {
  -webkit-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.radial-progress[data-progress="27"] .circle .mask.full,
.radial-progress[data-progress="27"] .circle .fill {
  -webkit-transform: rotate(48.6deg);
  -ms-transform: rotate(48.6deg);
  transform: rotate(48.6deg);
}
.radial-progress[data-progress="27"] .circle .fill.fix {
  -webkit-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.radial-progress[data-progress="28"] .circle .mask.full,
.radial-progress[data-progress="28"] .circle .fill {
  -webkit-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.radial-progress[data-progress="28"] .circle .fill.fix {
  -webkit-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.radial-progress[data-progress="29"] .circle .mask.full,
.radial-progress[data-progress="29"] .circle .fill {
  -webkit-transform: rotate(52.2deg);
  -ms-transform: rotate(52.2deg);
  transform: rotate(52.2deg);
}
.radial-progress[data-progress="29"] .circle .fill.fix {
  -webkit-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.radial-progress[data-progress="30"] .circle .mask.full,
.radial-progress[data-progress="30"] .circle .fill {
  -webkit-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  transform: rotate(54deg);
}
.radial-progress[data-progress="30"] .circle .fill.fix {
  -webkit-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  transform: rotate(108deg);
}
.radial-progress[data-progress="31"] .circle .mask.full,
.radial-progress[data-progress="31"] .circle .fill {
  -webkit-transform: rotate(55.8deg);
  -ms-transform: rotate(55.8deg);
  transform: rotate(55.8deg);
}
.radial-progress[data-progress="31"] .circle .fill.fix {
  -webkit-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.radial-progress[data-progress="32"] .circle .mask.full,
.radial-progress[data-progress="32"] .circle .fill {
  -webkit-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.radial-progress[data-progress="32"] .circle .fill.fix {
  -webkit-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.radial-progress[data-progress="33"] .circle .mask.full,
.radial-progress[data-progress="33"] .circle .fill {
  -webkit-transform: rotate(59.4deg);
  -ms-transform: rotate(59.4deg);
  transform: rotate(59.4deg);
}
.radial-progress[data-progress="33"] .circle .fill.fix {
  -webkit-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.radial-progress[data-progress="34"] .circle .mask.full,
.radial-progress[data-progress="34"] .circle .fill {
  -webkit-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.radial-progress[data-progress="34"] .circle .fill.fix {
  -webkit-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.radial-progress[data-progress="35"] .circle .mask.full,
.radial-progress[data-progress="35"] .circle .fill {
  -webkit-transform: rotate(63deg);
  -ms-transform: rotate(63deg);
  transform: rotate(63deg);
}
.radial-progress[data-progress="35"] .circle .fill.fix {
  -webkit-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  transform: rotate(126deg);
}
.radial-progress[data-progress="36"] .circle .mask.full,
.radial-progress[data-progress="36"] .circle .fill {
  -webkit-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.radial-progress[data-progress="36"] .circle .fill.fix {
  -webkit-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.radial-progress[data-progress="37"] .circle .mask.full,
.radial-progress[data-progress="37"] .circle .fill {
  -webkit-transform: rotate(66.6deg);
  -ms-transform: rotate(66.6deg);
  transform: rotate(66.6deg);
}
.radial-progress[data-progress="37"] .circle .fill.fix {
  -webkit-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.radial-progress[data-progress="38"] .circle .mask.full,
.radial-progress[data-progress="38"] .circle .fill {
  -webkit-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.radial-progress[data-progress="38"] .circle .fill.fix {
  -webkit-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.radial-progress[data-progress="39"] .circle .mask.full,
.radial-progress[data-progress="39"] .circle .fill {
  -webkit-transform: rotate(70.2deg);
  -ms-transform: rotate(70.2deg);
  transform: rotate(70.2deg);
}
.radial-progress[data-progress="39"] .circle .fill.fix {
  -webkit-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.radial-progress[data-progress="40"] .circle .mask.full,
.radial-progress[data-progress="40"] .circle .fill {
  -webkit-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  transform: rotate(72deg);
}
.radial-progress[data-progress="40"] .circle .fill.fix {
  -webkit-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  transform: rotate(144deg);
}
.radial-progress[data-progress="41"] .circle .mask.full,
.radial-progress[data-progress="41"] .circle .fill {
  -webkit-transform: rotate(73.8deg);
  -ms-transform: rotate(73.8deg);
  transform: rotate(73.8deg);
}
.radial-progress[data-progress="41"] .circle .fill.fix {
  -webkit-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.radial-progress[data-progress="42"] .circle .mask.full,
.radial-progress[data-progress="42"] .circle .fill {
  -webkit-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.radial-progress[data-progress="42"] .circle .fill.fix {
  -webkit-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.radial-progress[data-progress="43"] .circle .mask.full,
.radial-progress[data-progress="43"] .circle .fill {
  -webkit-transform: rotate(77.4deg);
  -ms-transform: rotate(77.4deg);
  transform: rotate(77.4deg);
}
.radial-progress[data-progress="43"] .circle .fill.fix {
  -webkit-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.radial-progress[data-progress="44"] .circle .mask.full,
.radial-progress[data-progress="44"] .circle .fill {
  -webkit-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.radial-progress[data-progress="44"] .circle .fill.fix {
  -webkit-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.radial-progress[data-progress="45"] .circle .mask.full,
.radial-progress[data-progress="45"] .circle .fill {
  -webkit-transform: rotate(81deg);
  -ms-transform: rotate(81deg);
  transform: rotate(81deg);
}
.radial-progress[data-progress="45"] .circle .fill.fix {
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}
.radial-progress[data-progress="46"] .circle .mask.full,
.radial-progress[data-progress="46"] .circle .fill {
  -webkit-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.radial-progress[data-progress="46"] .circle .fill.fix {
  -webkit-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.radial-progress[data-progress="47"] .circle .mask.full,
.radial-progress[data-progress="47"] .circle .fill {
  -webkit-transform: rotate(84.6deg);
  -ms-transform: rotate(84.6deg);
  transform: rotate(84.6deg);
}
.radial-progress[data-progress="47"] .circle .fill.fix {
  -webkit-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.radial-progress[data-progress="48"] .circle .mask.full,
.radial-progress[data-progress="48"] .circle .fill {
  -webkit-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.radial-progress[data-progress="48"] .circle .fill.fix {
  -webkit-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.radial-progress[data-progress="49"] .circle .mask.full,
.radial-progress[data-progress="49"] .circle .fill {
  -webkit-transform: rotate(88.2deg);
  -ms-transform: rotate(88.2deg);
  transform: rotate(88.2deg);
}
.radial-progress[data-progress="49"] .circle .fill.fix {
  -webkit-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.radial-progress[data-progress="50"] .circle .mask.full,
.radial-progress[data-progress="50"] .circle .fill {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.radial-progress[data-progress="50"] .circle .fill.fix {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.radial-progress[data-progress="51"] .circle .mask.full,
.radial-progress[data-progress="51"] .circle .fill {
  -webkit-transform: rotate(91.8deg);
  -ms-transform: rotate(91.8deg);
  transform: rotate(91.8deg);
}
.radial-progress[data-progress="51"] .circle .fill.fix {
  -webkit-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.radial-progress[data-progress="52"] .circle .mask.full,
.radial-progress[data-progress="52"] .circle .fill {
  -webkit-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.radial-progress[data-progress="52"] .circle .fill.fix {
  -webkit-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.radial-progress[data-progress="53"] .circle .mask.full,
.radial-progress[data-progress="53"] .circle .fill {
  -webkit-transform: rotate(95.4deg);
  -ms-transform: rotate(95.4deg);
  transform: rotate(95.4deg);
}
.radial-progress[data-progress="53"] .circle .fill.fix {
  -webkit-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.radial-progress[data-progress="54"] .circle .mask.full,
.radial-progress[data-progress="54"] .circle .fill {
  -webkit-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.radial-progress[data-progress="54"] .circle .fill.fix {
  -webkit-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.radial-progress[data-progress="55"] .circle .mask.full,
.radial-progress[data-progress="55"] .circle .fill {
  -webkit-transform: rotate(99deg);
  -ms-transform: rotate(99deg);
  transform: rotate(99deg);
}
.radial-progress[data-progress="55"] .circle .fill.fix {
  -webkit-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  transform: rotate(198deg);
}
.radial-progress[data-progress="56"] .circle .mask.full,
.radial-progress[data-progress="56"] .circle .fill {
  -webkit-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.radial-progress[data-progress="56"] .circle .fill.fix {
  -webkit-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.radial-progress[data-progress="57"] .circle .mask.full,
.radial-progress[data-progress="57"] .circle .fill {
  -webkit-transform: rotate(102.6deg);
  -ms-transform: rotate(102.6deg);
  transform: rotate(102.6deg);
}
.radial-progress[data-progress="57"] .circle .fill.fix {
  -webkit-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.radial-progress[data-progress="58"] .circle .mask.full,
.radial-progress[data-progress="58"] .circle .fill {
  -webkit-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.radial-progress[data-progress="58"] .circle .fill.fix {
  -webkit-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.radial-progress[data-progress="59"] .circle .mask.full,
.radial-progress[data-progress="59"] .circle .fill {
  -webkit-transform: rotate(106.2deg);
  -ms-transform: rotate(106.2deg);
  transform: rotate(106.2deg);
}
.radial-progress[data-progress="59"] .circle .fill.fix {
  -webkit-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.radial-progress[data-progress="60"] .circle .mask.full,
.radial-progress[data-progress="60"] .circle .fill {
  -webkit-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  transform: rotate(108deg);
}
.radial-progress[data-progress="60"] .circle .fill.fix {
  -webkit-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  transform: rotate(216deg);
}
.radial-progress[data-progress="61"] .circle .mask.full,
.radial-progress[data-progress="61"] .circle .fill {
  -webkit-transform: rotate(109.8deg);
  -ms-transform: rotate(109.8deg);
  transform: rotate(109.8deg);
}
.radial-progress[data-progress="61"] .circle .fill.fix {
  -webkit-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.radial-progress[data-progress="62"] .circle .mask.full,
.radial-progress[data-progress="62"] .circle .fill {
  -webkit-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.radial-progress[data-progress="62"] .circle .fill.fix {
  -webkit-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.radial-progress[data-progress="63"] .circle .mask.full,
.radial-progress[data-progress="63"] .circle .fill {
  -webkit-transform: rotate(113.4deg);
  -ms-transform: rotate(113.4deg);
  transform: rotate(113.4deg);
}
.radial-progress[data-progress="63"] .circle .fill.fix {
  -webkit-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.radial-progress[data-progress="64"] .circle .mask.full,
.radial-progress[data-progress="64"] .circle .fill {
  -webkit-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.radial-progress[data-progress="64"] .circle .fill.fix {
  -webkit-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.radial-progress[data-progress="65"] .circle .mask.full,
.radial-progress[data-progress="65"] .circle .fill {
  -webkit-transform: rotate(117deg);
  -ms-transform: rotate(117deg);
  transform: rotate(117deg);
}
.radial-progress[data-progress="65"] .circle .fill.fix {
  -webkit-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  transform: rotate(234deg);
}
.radial-progress[data-progress="66"] .circle .mask.full,
.radial-progress[data-progress="66"] .circle .fill {
  -webkit-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.radial-progress[data-progress="66"] .circle .fill.fix {
  -webkit-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.radial-progress[data-progress="67"] .circle .mask.full,
.radial-progress[data-progress="67"] .circle .fill {
  -webkit-transform: rotate(120.6deg);
  -ms-transform: rotate(120.6deg);
  transform: rotate(120.6deg);
}
.radial-progress[data-progress="67"] .circle .fill.fix {
  -webkit-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.radial-progress[data-progress="68"] .circle .mask.full,
.radial-progress[data-progress="68"] .circle .fill {
  -webkit-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.radial-progress[data-progress="68"] .circle .fill.fix {
  -webkit-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.radial-progress[data-progress="69"] .circle .mask.full,
.radial-progress[data-progress="69"] .circle .fill {
  -webkit-transform: rotate(124.2deg);
  -ms-transform: rotate(124.2deg);
  transform: rotate(124.2deg);
}
.radial-progress[data-progress="69"] .circle .fill.fix {
  -webkit-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.radial-progress[data-progress="70"] .circle .mask.full,
.radial-progress[data-progress="70"] .circle .fill {
  -webkit-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  transform: rotate(126deg);
}
.radial-progress[data-progress="70"] .circle .fill.fix {
  -webkit-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  transform: rotate(252deg);
}
.radial-progress[data-progress="71"] .circle .mask.full,
.radial-progress[data-progress="71"] .circle .fill {
  -webkit-transform: rotate(127.8deg);
  -ms-transform: rotate(127.8deg);
  transform: rotate(127.8deg);
}
.radial-progress[data-progress="71"] .circle .fill.fix {
  -webkit-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.radial-progress[data-progress="72"] .circle .mask.full,
.radial-progress[data-progress="72"] .circle .fill {
  -webkit-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.radial-progress[data-progress="72"] .circle .fill.fix {
  -webkit-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.radial-progress[data-progress="73"] .circle .mask.full,
.radial-progress[data-progress="73"] .circle .fill {
  -webkit-transform: rotate(131.4deg);
  -ms-transform: rotate(131.4deg);
  transform: rotate(131.4deg);
}
.radial-progress[data-progress="73"] .circle .fill.fix {
  -webkit-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.radial-progress[data-progress="74"] .circle .mask.full,
.radial-progress[data-progress="74"] .circle .fill {
  -webkit-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.radial-progress[data-progress="74"] .circle .fill.fix {
  -webkit-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.radial-progress[data-progress="75"] .circle .mask.full,
.radial-progress[data-progress="75"] .circle .fill {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.radial-progress[data-progress="75"] .circle .fill.fix {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.radial-progress[data-progress="76"] .circle .mask.full,
.radial-progress[data-progress="76"] .circle .fill {
  -webkit-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.radial-progress[data-progress="76"] .circle .fill.fix {
  -webkit-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.radial-progress[data-progress="77"] .circle .mask.full,
.radial-progress[data-progress="77"] .circle .fill {
  -webkit-transform: rotate(138.6deg);
  -ms-transform: rotate(138.6deg);
  transform: rotate(138.6deg);
}
.radial-progress[data-progress="77"] .circle .fill.fix {
  -webkit-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.radial-progress[data-progress="78"] .circle .mask.full,
.radial-progress[data-progress="78"] .circle .fill {
  -webkit-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.radial-progress[data-progress="78"] .circle .fill.fix {
  -webkit-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.radial-progress[data-progress="79"] .circle .mask.full,
.radial-progress[data-progress="79"] .circle .fill {
  -webkit-transform: rotate(142.2deg);
  -ms-transform: rotate(142.2deg);
  transform: rotate(142.2deg);
}
.radial-progress[data-progress="79"] .circle .fill.fix {
  -webkit-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.radial-progress[data-progress="80"] .circle .mask.full,
.radial-progress[data-progress="80"] .circle .fill {
  -webkit-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  transform: rotate(144deg);
}
.radial-progress[data-progress="80"] .circle .fill.fix {
  -webkit-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  transform: rotate(288deg);
}
.radial-progress[data-progress="81"] .circle .mask.full,
.radial-progress[data-progress="81"] .circle .fill {
  -webkit-transform: rotate(145.8deg);
  -ms-transform: rotate(145.8deg);
  transform: rotate(145.8deg);
}
.radial-progress[data-progress="81"] .circle .fill.fix {
  -webkit-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.radial-progress[data-progress="82"] .circle .mask.full,
.radial-progress[data-progress="82"] .circle .fill {
  -webkit-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.radial-progress[data-progress="82"] .circle .fill.fix {
  -webkit-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.radial-progress[data-progress="83"] .circle .mask.full,
.radial-progress[data-progress="83"] .circle .fill {
  -webkit-transform: rotate(149.4deg);
  -ms-transform: rotate(149.4deg);
  transform: rotate(149.4deg);
}
.radial-progress[data-progress="83"] .circle .fill.fix {
  -webkit-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.radial-progress[data-progress="84"] .circle .mask.full,
.radial-progress[data-progress="84"] .circle .fill {
  -webkit-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.radial-progress[data-progress="84"] .circle .fill.fix {
  -webkit-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.radial-progress[data-progress="85"] .circle .mask.full,
.radial-progress[data-progress="85"] .circle .fill {
  -webkit-transform: rotate(153deg);
  -ms-transform: rotate(153deg);
  transform: rotate(153deg);
}
.radial-progress[data-progress="85"] .circle .fill.fix {
  -webkit-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  transform: rotate(306deg);
}
.radial-progress[data-progress="86"] .circle .mask.full,
.radial-progress[data-progress="86"] .circle .fill {
  -webkit-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.radial-progress[data-progress="86"] .circle .fill.fix {
  -webkit-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.radial-progress[data-progress="87"] .circle .mask.full,
.radial-progress[data-progress="87"] .circle .fill {
  -webkit-transform: rotate(156.6deg);
  -ms-transform: rotate(156.6deg);
  transform: rotate(156.6deg);
}
.radial-progress[data-progress="87"] .circle .fill.fix {
  -webkit-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.radial-progress[data-progress="88"] .circle .mask.full,
.radial-progress[data-progress="88"] .circle .fill {
  -webkit-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.radial-progress[data-progress="88"] .circle .fill.fix {
  -webkit-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.radial-progress[data-progress="89"] .circle .mask.full,
.radial-progress[data-progress="89"] .circle .fill {
  -webkit-transform: rotate(160.2deg);
  -ms-transform: rotate(160.2deg);
  transform: rotate(160.2deg);
}
.radial-progress[data-progress="89"] .circle .fill.fix {
  -webkit-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.radial-progress[data-progress="90"] .circle .mask.full,
.radial-progress[data-progress="90"] .circle .fill {
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}
.radial-progress[data-progress="90"] .circle .fill.fix {
  -webkit-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  transform: rotate(324deg);
}
.radial-progress[data-progress="91"] .circle .mask.full,
.radial-progress[data-progress="91"] .circle .fill {
  -webkit-transform: rotate(163.8deg);
  -ms-transform: rotate(163.8deg);
  transform: rotate(163.8deg);
}
.radial-progress[data-progress="91"] .circle .fill.fix {
  -webkit-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.radial-progress[data-progress="92"] .circle .mask.full,
.radial-progress[data-progress="92"] .circle .fill {
  -webkit-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.radial-progress[data-progress="92"] .circle .fill.fix {
  -webkit-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.radial-progress[data-progress="93"] .circle .mask.full,
.radial-progress[data-progress="93"] .circle .fill {
  -webkit-transform: rotate(167.4deg);
  -ms-transform: rotate(167.4deg);
  transform: rotate(167.4deg);
}
.radial-progress[data-progress="93"] .circle .fill.fix {
  -webkit-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.radial-progress[data-progress="94"] .circle .mask.full,
.radial-progress[data-progress="94"] .circle .fill {
  -webkit-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.radial-progress[data-progress="94"] .circle .fill.fix {
  -webkit-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.radial-progress[data-progress="95"] .circle .mask.full,
.radial-progress[data-progress="95"] .circle .fill {
  -webkit-transform: rotate(171deg);
  -ms-transform: rotate(171deg);
  transform: rotate(171deg);
}
.radial-progress[data-progress="95"] .circle .fill.fix {
  -webkit-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  transform: rotate(342deg);
}
.radial-progress[data-progress="96"] .circle .mask.full,
.radial-progress[data-progress="96"] .circle .fill {
  -webkit-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.radial-progress[data-progress="96"] .circle .fill.fix {
  -webkit-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.radial-progress[data-progress="97"] .circle .mask.full,
.radial-progress[data-progress="97"] .circle .fill {
  -webkit-transform: rotate(174.6deg);
  -ms-transform: rotate(174.6deg);
  transform: rotate(174.6deg);
}
.radial-progress[data-progress="97"] .circle .fill.fix {
  -webkit-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.radial-progress[data-progress="98"] .circle .mask.full,
.radial-progress[data-progress="98"] .circle .fill {
  -webkit-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.radial-progress[data-progress="98"] .circle .fill.fix {
  -webkit-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.radial-progress[data-progress="99"] .circle .mask.full,
.radial-progress[data-progress="99"] .circle .fill {
  -webkit-transform: rotate(178.2deg);
  -ms-transform: rotate(178.2deg);
  transform: rotate(178.2deg);
}
.radial-progress[data-progress="99"] .circle .fill.fix {
  -webkit-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.radial-progress[data-progress="100"] .circle .mask.full,
.radial-progress[data-progress="100"] .circle .fill {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.radial-progress[data-progress="100"] .circle .fill.fix {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.radial-progress-big {
  margin-right: 40px;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
}
.radial-progress-big .circle .mask,
.radial-progress-big .circle .fill,
.radial-progress-big .circle .shadow {
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
}
.radial-progress-big .circle .mask,
.radial-progress-big .circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: -ms-transform 1s;
  transition: transform 1s;
  border-radius: 50%;
}
.radial-progress-big .circle .mask {
  clip: rect(0px, 60px, 60px, 30px);
}
.radial-progress-big .circle .mask .fill {
  clip: rect(0px, 30px, 60px, 0px);
  background-color: #7231F8;
}
.radial-progress-big .inset {
  width: 56px;
  height: 56px;
  position: absolute;
  margin-left: 2px;
  margin-top: 2px;
  background-color: #f6f6f6;
  border-radius: 50%;
}
.radial-progress-big .inset .percentage {
  width: 57px;
  position: absolute;
  top: 20px;
  left: -0.5px;
  line-height: 1;
  text-align: center;
  color: #000;
  font-weight: 800;
  font-size: 16px;
}
.radial-progress-big[data-progress="0"] .circle .mask.full,
.radial-progress-big[data-progress="0"] .circle .fill {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.radial-progress-big[data-progress="0"] .circle .fill.fix {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.radial-progress-big[data-progress="1"] .circle .mask.full,
.radial-progress-big[data-progress="1"] .circle .fill {
  -webkit-transform: rotate(1.8deg);
  -ms-transform: rotate(1.8deg);
  transform: rotate(1.8deg);
}
.radial-progress-big[data-progress="1"] .circle .fill.fix {
  -webkit-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.radial-progress-big[data-progress="2"] .circle .mask.full,
.radial-progress-big[data-progress="2"] .circle .fill {
  -webkit-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.radial-progress-big[data-progress="2"] .circle .fill.fix {
  -webkit-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.radial-progress-big[data-progress="3"] .circle .mask.full,
.radial-progress-big[data-progress="3"] .circle .fill {
  -webkit-transform: rotate(5.4deg);
  -ms-transform: rotate(5.4deg);
  transform: rotate(5.4deg);
}
.radial-progress-big[data-progress="3"] .circle .fill.fix {
  -webkit-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.radial-progress-big[data-progress="4"] .circle .mask.full,
.radial-progress-big[data-progress="4"] .circle .fill {
  -webkit-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.radial-progress-big[data-progress="4"] .circle .fill.fix {
  -webkit-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.radial-progress-big[data-progress="5"] .circle .mask.full,
.radial-progress-big[data-progress="5"] .circle .fill {
  -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg);
}
.radial-progress-big[data-progress="5"] .circle .fill.fix {
  -webkit-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  transform: rotate(18deg);
}
.radial-progress-big[data-progress="6"] .circle .mask.full,
.radial-progress-big[data-progress="6"] .circle .fill {
  -webkit-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.radial-progress-big[data-progress="6"] .circle .fill.fix {
  -webkit-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.radial-progress-big[data-progress="7"] .circle .mask.full,
.radial-progress-big[data-progress="7"] .circle .fill {
  -webkit-transform: rotate(12.6deg);
  -ms-transform: rotate(12.6deg);
  transform: rotate(12.6deg);
}
.radial-progress-big[data-progress="7"] .circle .fill.fix {
  -webkit-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.radial-progress-big[data-progress="8"] .circle .mask.full,
.radial-progress-big[data-progress="8"] .circle .fill {
  -webkit-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.radial-progress-big[data-progress="8"] .circle .fill.fix {
  -webkit-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.radial-progress-big[data-progress="9"] .circle .mask.full,
.radial-progress-big[data-progress="9"] .circle .fill {
  -webkit-transform: rotate(16.2deg);
  -ms-transform: rotate(16.2deg);
  transform: rotate(16.2deg);
}
.radial-progress-big[data-progress="9"] .circle .fill.fix {
  -webkit-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.radial-progress-big[data-progress="10"] .circle .mask.full,
.radial-progress-big[data-progress="10"] .circle .fill {
  -webkit-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  transform: rotate(18deg);
}
.radial-progress-big[data-progress="10"] .circle .fill.fix {
  -webkit-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  transform: rotate(36deg);
}
.radial-progress-big[data-progress="11"] .circle .mask.full,
.radial-progress-big[data-progress="11"] .circle .fill {
  -webkit-transform: rotate(19.8deg);
  -ms-transform: rotate(19.8deg);
  transform: rotate(19.8deg);
}
.radial-progress-big[data-progress="11"] .circle .fill.fix {
  -webkit-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.radial-progress-big[data-progress="12"] .circle .mask.full,
.radial-progress-big[data-progress="12"] .circle .fill {
  -webkit-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.radial-progress-big[data-progress="12"] .circle .fill.fix {
  -webkit-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.radial-progress-big[data-progress="13"] .circle .mask.full,
.radial-progress-big[data-progress="13"] .circle .fill {
  -webkit-transform: rotate(23.4deg);
  -ms-transform: rotate(23.4deg);
  transform: rotate(23.4deg);
}
.radial-progress-big[data-progress="13"] .circle .fill.fix {
  -webkit-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.radial-progress-big[data-progress="14"] .circle .mask.full,
.radial-progress-big[data-progress="14"] .circle .fill {
  -webkit-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.radial-progress-big[data-progress="14"] .circle .fill.fix {
  -webkit-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.radial-progress-big[data-progress="15"] .circle .mask.full,
.radial-progress-big[data-progress="15"] .circle .fill {
  -webkit-transform: rotate(27deg);
  -ms-transform: rotate(27deg);
  transform: rotate(27deg);
}
.radial-progress-big[data-progress="15"] .circle .fill.fix {
  -webkit-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  transform: rotate(54deg);
}
.radial-progress-big[data-progress="16"] .circle .mask.full,
.radial-progress-big[data-progress="16"] .circle .fill {
  -webkit-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.radial-progress-big[data-progress="16"] .circle .fill.fix {
  -webkit-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.radial-progress-big[data-progress="17"] .circle .mask.full,
.radial-progress-big[data-progress="17"] .circle .fill {
  -webkit-transform: rotate(30.6deg);
  -ms-transform: rotate(30.6deg);
  transform: rotate(30.6deg);
}
.radial-progress-big[data-progress="17"] .circle .fill.fix {
  -webkit-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.radial-progress-big[data-progress="18"] .circle .mask.full,
.radial-progress-big[data-progress="18"] .circle .fill {
  -webkit-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.radial-progress-big[data-progress="18"] .circle .fill.fix {
  -webkit-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.radial-progress-big[data-progress="19"] .circle .mask.full,
.radial-progress-big[data-progress="19"] .circle .fill {
  -webkit-transform: rotate(34.2deg);
  -ms-transform: rotate(34.2deg);
  transform: rotate(34.2deg);
}
.radial-progress-big[data-progress="19"] .circle .fill.fix {
  -webkit-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.radial-progress-big[data-progress="20"] .circle .mask.full,
.radial-progress-big[data-progress="20"] .circle .fill {
  -webkit-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  transform: rotate(36deg);
}
.radial-progress-big[data-progress="20"] .circle .fill.fix {
  -webkit-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  transform: rotate(72deg);
}
.radial-progress-big[data-progress="21"] .circle .mask.full,
.radial-progress-big[data-progress="21"] .circle .fill {
  -webkit-transform: rotate(37.8deg);
  -ms-transform: rotate(37.8deg);
  transform: rotate(37.8deg);
}
.radial-progress-big[data-progress="21"] .circle .fill.fix {
  -webkit-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.radial-progress-big[data-progress="22"] .circle .mask.full,
.radial-progress-big[data-progress="22"] .circle .fill {
  -webkit-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.radial-progress-big[data-progress="22"] .circle .fill.fix {
  -webkit-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.radial-progress-big[data-progress="23"] .circle .mask.full,
.radial-progress-big[data-progress="23"] .circle .fill {
  -webkit-transform: rotate(41.4deg);
  -ms-transform: rotate(41.4deg);
  transform: rotate(41.4deg);
}
.radial-progress-big[data-progress="23"] .circle .fill.fix {
  -webkit-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.radial-progress-big[data-progress="24"] .circle .mask.full,
.radial-progress-big[data-progress="24"] .circle .fill {
  -webkit-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.radial-progress-big[data-progress="24"] .circle .fill.fix {
  -webkit-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.radial-progress-big[data-progress="25"] .circle .mask.full,
.radial-progress-big[data-progress="25"] .circle .fill {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.radial-progress-big[data-progress="25"] .circle .fill.fix {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.radial-progress-big[data-progress="26"] .circle .mask.full,
.radial-progress-big[data-progress="26"] .circle .fill {
  -webkit-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.radial-progress-big[data-progress="26"] .circle .fill.fix {
  -webkit-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.radial-progress-big[data-progress="27"] .circle .mask.full,
.radial-progress-big[data-progress="27"] .circle .fill {
  -webkit-transform: rotate(48.6deg);
  -ms-transform: rotate(48.6deg);
  transform: rotate(48.6deg);
}
.radial-progress-big[data-progress="27"] .circle .fill.fix {
  -webkit-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.radial-progress-big[data-progress="28"] .circle .mask.full,
.radial-progress-big[data-progress="28"] .circle .fill {
  -webkit-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.radial-progress-big[data-progress="28"] .circle .fill.fix {
  -webkit-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.radial-progress-big[data-progress="29"] .circle .mask.full,
.radial-progress-big[data-progress="29"] .circle .fill {
  -webkit-transform: rotate(52.2deg);
  -ms-transform: rotate(52.2deg);
  transform: rotate(52.2deg);
}
.radial-progress-big[data-progress="29"] .circle .fill.fix {
  -webkit-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.radial-progress-big[data-progress="30"] .circle .mask.full,
.radial-progress-big[data-progress="30"] .circle .fill {
  -webkit-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  transform: rotate(54deg);
}
.radial-progress-big[data-progress="30"] .circle .fill.fix {
  -webkit-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  transform: rotate(108deg);
}
.radial-progress-big[data-progress="31"] .circle .mask.full,
.radial-progress-big[data-progress="31"] .circle .fill {
  -webkit-transform: rotate(55.8deg);
  -ms-transform: rotate(55.8deg);
  transform: rotate(55.8deg);
}
.radial-progress-big[data-progress="31"] .circle .fill.fix {
  -webkit-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.radial-progress-big[data-progress="32"] .circle .mask.full,
.radial-progress-big[data-progress="32"] .circle .fill {
  -webkit-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.radial-progress-big[data-progress="32"] .circle .fill.fix {
  -webkit-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.radial-progress-big[data-progress="33"] .circle .mask.full,
.radial-progress-big[data-progress="33"] .circle .fill {
  -webkit-transform: rotate(59.4deg);
  -ms-transform: rotate(59.4deg);
  transform: rotate(59.4deg);
}
.radial-progress-big[data-progress="33"] .circle .fill.fix {
  -webkit-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.radial-progress-big[data-progress="34"] .circle .mask.full,
.radial-progress-big[data-progress="34"] .circle .fill {
  -webkit-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.radial-progress-big[data-progress="34"] .circle .fill.fix {
  -webkit-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.radial-progress-big[data-progress="35"] .circle .mask.full,
.radial-progress-big[data-progress="35"] .circle .fill {
  -webkit-transform: rotate(63deg);
  -ms-transform: rotate(63deg);
  transform: rotate(63deg);
}
.radial-progress-big[data-progress="35"] .circle .fill.fix {
  -webkit-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  transform: rotate(126deg);
}
.radial-progress-big[data-progress="36"] .circle .mask.full,
.radial-progress-big[data-progress="36"] .circle .fill {
  -webkit-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.radial-progress-big[data-progress="36"] .circle .fill.fix {
  -webkit-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.radial-progress-big[data-progress="37"] .circle .mask.full,
.radial-progress-big[data-progress="37"] .circle .fill {
  -webkit-transform: rotate(66.6deg);
  -ms-transform: rotate(66.6deg);
  transform: rotate(66.6deg);
}
.radial-progress-big[data-progress="37"] .circle .fill.fix {
  -webkit-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.radial-progress-big[data-progress="38"] .circle .mask.full,
.radial-progress-big[data-progress="38"] .circle .fill {
  -webkit-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.radial-progress-big[data-progress="38"] .circle .fill.fix {
  -webkit-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.radial-progress-big[data-progress="39"] .circle .mask.full,
.radial-progress-big[data-progress="39"] .circle .fill {
  -webkit-transform: rotate(70.2deg);
  -ms-transform: rotate(70.2deg);
  transform: rotate(70.2deg);
}
.radial-progress-big[data-progress="39"] .circle .fill.fix {
  -webkit-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.radial-progress-big[data-progress="40"] .circle .mask.full,
.radial-progress-big[data-progress="40"] .circle .fill {
  -webkit-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  transform: rotate(72deg);
}
.radial-progress-big[data-progress="40"] .circle .fill.fix {
  -webkit-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  transform: rotate(144deg);
}
.radial-progress-big[data-progress="41"] .circle .mask.full,
.radial-progress-big[data-progress="41"] .circle .fill {
  -webkit-transform: rotate(73.8deg);
  -ms-transform: rotate(73.8deg);
  transform: rotate(73.8deg);
}
.radial-progress-big[data-progress="41"] .circle .fill.fix {
  -webkit-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.radial-progress-big[data-progress="42"] .circle .mask.full,
.radial-progress-big[data-progress="42"] .circle .fill {
  -webkit-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.radial-progress-big[data-progress="42"] .circle .fill.fix {
  -webkit-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.radial-progress-big[data-progress="43"] .circle .mask.full,
.radial-progress-big[data-progress="43"] .circle .fill {
  -webkit-transform: rotate(77.4deg);
  -ms-transform: rotate(77.4deg);
  transform: rotate(77.4deg);
}
.radial-progress-big[data-progress="43"] .circle .fill.fix {
  -webkit-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.radial-progress-big[data-progress="44"] .circle .mask.full,
.radial-progress-big[data-progress="44"] .circle .fill {
  -webkit-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.radial-progress-big[data-progress="44"] .circle .fill.fix {
  -webkit-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.radial-progress-big[data-progress="45"] .circle .mask.full,
.radial-progress-big[data-progress="45"] .circle .fill {
  -webkit-transform: rotate(81deg);
  -ms-transform: rotate(81deg);
  transform: rotate(81deg);
}
.radial-progress-big[data-progress="45"] .circle .fill.fix {
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}
.radial-progress-big[data-progress="46"] .circle .mask.full,
.radial-progress-big[data-progress="46"] .circle .fill {
  -webkit-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.radial-progress-big[data-progress="46"] .circle .fill.fix {
  -webkit-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.radial-progress-big[data-progress="47"] .circle .mask.full,
.radial-progress-big[data-progress="47"] .circle .fill {
  -webkit-transform: rotate(84.6deg);
  -ms-transform: rotate(84.6deg);
  transform: rotate(84.6deg);
}
.radial-progress-big[data-progress="47"] .circle .fill.fix {
  -webkit-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.radial-progress-big[data-progress="48"] .circle .mask.full,
.radial-progress-big[data-progress="48"] .circle .fill {
  -webkit-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.radial-progress-big[data-progress="48"] .circle .fill.fix {
  -webkit-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.radial-progress-big[data-progress="49"] .circle .mask.full,
.radial-progress-big[data-progress="49"] .circle .fill {
  -webkit-transform: rotate(88.2deg);
  -ms-transform: rotate(88.2deg);
  transform: rotate(88.2deg);
}
.radial-progress-big[data-progress="49"] .circle .fill.fix {
  -webkit-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.radial-progress-big[data-progress="50"] .circle .mask.full,
.radial-progress-big[data-progress="50"] .circle .fill {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.radial-progress-big[data-progress="50"] .circle .fill.fix {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.radial-progress-big[data-progress="51"] .circle .mask.full,
.radial-progress-big[data-progress="51"] .circle .fill {
  -webkit-transform: rotate(91.8deg);
  -ms-transform: rotate(91.8deg);
  transform: rotate(91.8deg);
}
.radial-progress-big[data-progress="51"] .circle .fill.fix {
  -webkit-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.radial-progress-big[data-progress="52"] .circle .mask.full,
.radial-progress-big[data-progress="52"] .circle .fill {
  -webkit-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.radial-progress-big[data-progress="52"] .circle .fill.fix {
  -webkit-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.radial-progress-big[data-progress="53"] .circle .mask.full,
.radial-progress-big[data-progress="53"] .circle .fill {
  -webkit-transform: rotate(95.4deg);
  -ms-transform: rotate(95.4deg);
  transform: rotate(95.4deg);
}
.radial-progress-big[data-progress="53"] .circle .fill.fix {
  -webkit-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.radial-progress-big[data-progress="54"] .circle .mask.full,
.radial-progress-big[data-progress="54"] .circle .fill {
  -webkit-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.radial-progress-big[data-progress="54"] .circle .fill.fix {
  -webkit-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.radial-progress-big[data-progress="55"] .circle .mask.full,
.radial-progress-big[data-progress="55"] .circle .fill {
  -webkit-transform: rotate(99deg);
  -ms-transform: rotate(99deg);
  transform: rotate(99deg);
}
.radial-progress-big[data-progress="55"] .circle .fill.fix {
  -webkit-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  transform: rotate(198deg);
}
.radial-progress-big[data-progress="56"] .circle .mask.full,
.radial-progress-big[data-progress="56"] .circle .fill {
  -webkit-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.radial-progress-big[data-progress="56"] .circle .fill.fix {
  -webkit-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.radial-progress-big[data-progress="57"] .circle .mask.full,
.radial-progress-big[data-progress="57"] .circle .fill {
  -webkit-transform: rotate(102.6deg);
  -ms-transform: rotate(102.6deg);
  transform: rotate(102.6deg);
}
.radial-progress-big[data-progress="57"] .circle .fill.fix {
  -webkit-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.radial-progress-big[data-progress="58"] .circle .mask.full,
.radial-progress-big[data-progress="58"] .circle .fill {
  -webkit-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.radial-progress-big[data-progress="58"] .circle .fill.fix {
  -webkit-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.radial-progress-big[data-progress="59"] .circle .mask.full,
.radial-progress-big[data-progress="59"] .circle .fill {
  -webkit-transform: rotate(106.2deg);
  -ms-transform: rotate(106.2deg);
  transform: rotate(106.2deg);
}
.radial-progress-big[data-progress="59"] .circle .fill.fix {
  -webkit-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.radial-progress-big[data-progress="60"] .circle .mask.full,
.radial-progress-big[data-progress="60"] .circle .fill {
  -webkit-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  transform: rotate(108deg);
}
.radial-progress-big[data-progress="60"] .circle .fill.fix {
  -webkit-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  transform: rotate(216deg);
}
.radial-progress-big[data-progress="61"] .circle .mask.full,
.radial-progress-big[data-progress="61"] .circle .fill {
  -webkit-transform: rotate(109.8deg);
  -ms-transform: rotate(109.8deg);
  transform: rotate(109.8deg);
}
.radial-progress-big[data-progress="61"] .circle .fill.fix {
  -webkit-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.radial-progress-big[data-progress="62"] .circle .mask.full,
.radial-progress-big[data-progress="62"] .circle .fill {
  -webkit-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.radial-progress-big[data-progress="62"] .circle .fill.fix {
  -webkit-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.radial-progress-big[data-progress="63"] .circle .mask.full,
.radial-progress-big[data-progress="63"] .circle .fill {
  -webkit-transform: rotate(113.4deg);
  -ms-transform: rotate(113.4deg);
  transform: rotate(113.4deg);
}
.radial-progress-big[data-progress="63"] .circle .fill.fix {
  -webkit-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.radial-progress-big[data-progress="64"] .circle .mask.full,
.radial-progress-big[data-progress="64"] .circle .fill {
  -webkit-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.radial-progress-big[data-progress="64"] .circle .fill.fix {
  -webkit-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.radial-progress-big[data-progress="65"] .circle .mask.full,
.radial-progress-big[data-progress="65"] .circle .fill {
  -webkit-transform: rotate(117deg);
  -ms-transform: rotate(117deg);
  transform: rotate(117deg);
}
.radial-progress-big[data-progress="65"] .circle .fill.fix {
  -webkit-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  transform: rotate(234deg);
}
.radial-progress-big[data-progress="66"] .circle .mask.full,
.radial-progress-big[data-progress="66"] .circle .fill {
  -webkit-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.radial-progress-big[data-progress="66"] .circle .fill.fix {
  -webkit-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.radial-progress-big[data-progress="67"] .circle .mask.full,
.radial-progress-big[data-progress="67"] .circle .fill {
  -webkit-transform: rotate(120.6deg);
  -ms-transform: rotate(120.6deg);
  transform: rotate(120.6deg);
}
.radial-progress-big[data-progress="67"] .circle .fill.fix {
  -webkit-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.radial-progress-big[data-progress="68"] .circle .mask.full,
.radial-progress-big[data-progress="68"] .circle .fill {
  -webkit-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.radial-progress-big[data-progress="68"] .circle .fill.fix {
  -webkit-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.radial-progress-big[data-progress="69"] .circle .mask.full,
.radial-progress-big[data-progress="69"] .circle .fill {
  -webkit-transform: rotate(124.2deg);
  -ms-transform: rotate(124.2deg);
  transform: rotate(124.2deg);
}
.radial-progress-big[data-progress="69"] .circle .fill.fix {
  -webkit-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.radial-progress-big[data-progress="70"] .circle .mask.full,
.radial-progress-big[data-progress="70"] .circle .fill {
  -webkit-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  transform: rotate(126deg);
}
.radial-progress-big[data-progress="70"] .circle .fill.fix {
  -webkit-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  transform: rotate(252deg);
}
.radial-progress-big[data-progress="71"] .circle .mask.full,
.radial-progress-big[data-progress="71"] .circle .fill {
  -webkit-transform: rotate(127.8deg);
  -ms-transform: rotate(127.8deg);
  transform: rotate(127.8deg);
}
.radial-progress-big[data-progress="71"] .circle .fill.fix {
  -webkit-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.radial-progress-big[data-progress="72"] .circle .mask.full,
.radial-progress-big[data-progress="72"] .circle .fill {
  -webkit-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.radial-progress-big[data-progress="72"] .circle .fill.fix {
  -webkit-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.radial-progress-big[data-progress="73"] .circle .mask.full,
.radial-progress-big[data-progress="73"] .circle .fill {
  -webkit-transform: rotate(131.4deg);
  -ms-transform: rotate(131.4deg);
  transform: rotate(131.4deg);
}
.radial-progress-big[data-progress="73"] .circle .fill.fix {
  -webkit-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.radial-progress-big[data-progress="74"] .circle .mask.full,
.radial-progress-big[data-progress="74"] .circle .fill {
  -webkit-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.radial-progress-big[data-progress="74"] .circle .fill.fix {
  -webkit-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.radial-progress-big[data-progress="75"] .circle .mask.full,
.radial-progress-big[data-progress="75"] .circle .fill {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.radial-progress-big[data-progress="75"] .circle .fill.fix {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.radial-progress-big[data-progress="76"] .circle .mask.full,
.radial-progress-big[data-progress="76"] .circle .fill {
  -webkit-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.radial-progress-big[data-progress="76"] .circle .fill.fix {
  -webkit-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.radial-progress-big[data-progress="77"] .circle .mask.full,
.radial-progress-big[data-progress="77"] .circle .fill {
  -webkit-transform: rotate(138.6deg);
  -ms-transform: rotate(138.6deg);
  transform: rotate(138.6deg);
}
.radial-progress-big[data-progress="77"] .circle .fill.fix {
  -webkit-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.radial-progress-big[data-progress="78"] .circle .mask.full,
.radial-progress-big[data-progress="78"] .circle .fill {
  -webkit-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.radial-progress-big[data-progress="78"] .circle .fill.fix {
  -webkit-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.radial-progress-big[data-progress="79"] .circle .mask.full,
.radial-progress-big[data-progress="79"] .circle .fill {
  -webkit-transform: rotate(142.2deg);
  -ms-transform: rotate(142.2deg);
  transform: rotate(142.2deg);
}
.radial-progress-big[data-progress="79"] .circle .fill.fix {
  -webkit-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.radial-progress-big[data-progress="80"] .circle .mask.full,
.radial-progress-big[data-progress="80"] .circle .fill {
  -webkit-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  transform: rotate(144deg);
}
.radial-progress-big[data-progress="80"] .circle .fill.fix {
  -webkit-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  transform: rotate(288deg);
}
.radial-progress-big[data-progress="81"] .circle .mask.full,
.radial-progress-big[data-progress="81"] .circle .fill {
  -webkit-transform: rotate(145.8deg);
  -ms-transform: rotate(145.8deg);
  transform: rotate(145.8deg);
}
.radial-progress-big[data-progress="81"] .circle .fill.fix {
  -webkit-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.radial-progress-big[data-progress="82"] .circle .mask.full,
.radial-progress-big[data-progress="82"] .circle .fill {
  -webkit-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.radial-progress-big[data-progress="82"] .circle .fill.fix {
  -webkit-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.radial-progress-big[data-progress="83"] .circle .mask.full,
.radial-progress-big[data-progress="83"] .circle .fill {
  -webkit-transform: rotate(149.4deg);
  -ms-transform: rotate(149.4deg);
  transform: rotate(149.4deg);
}
.radial-progress-big[data-progress="83"] .circle .fill.fix {
  -webkit-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.radial-progress-big[data-progress="84"] .circle .mask.full,
.radial-progress-big[data-progress="84"] .circle .fill {
  -webkit-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.radial-progress-big[data-progress="84"] .circle .fill.fix {
  -webkit-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.radial-progress-big[data-progress="85"] .circle .mask.full,
.radial-progress-big[data-progress="85"] .circle .fill {
  -webkit-transform: rotate(153deg);
  -ms-transform: rotate(153deg);
  transform: rotate(153deg);
}
.radial-progress-big[data-progress="85"] .circle .fill.fix {
  -webkit-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  transform: rotate(306deg);
}
.radial-progress-big[data-progress="86"] .circle .mask.full,
.radial-progress-big[data-progress="86"] .circle .fill {
  -webkit-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.radial-progress-big[data-progress="86"] .circle .fill.fix {
  -webkit-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.radial-progress-big[data-progress="87"] .circle .mask.full,
.radial-progress-big[data-progress="87"] .circle .fill {
  -webkit-transform: rotate(156.6deg);
  -ms-transform: rotate(156.6deg);
  transform: rotate(156.6deg);
}
.radial-progress-big[data-progress="87"] .circle .fill.fix {
  -webkit-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.radial-progress-big[data-progress="88"] .circle .mask.full,
.radial-progress-big[data-progress="88"] .circle .fill {
  -webkit-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.radial-progress-big[data-progress="88"] .circle .fill.fix {
  -webkit-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.radial-progress-big[data-progress="89"] .circle .mask.full,
.radial-progress-big[data-progress="89"] .circle .fill {
  -webkit-transform: rotate(160.2deg);
  -ms-transform: rotate(160.2deg);
  transform: rotate(160.2deg);
}
.radial-progress-big[data-progress="89"] .circle .fill.fix {
  -webkit-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.radial-progress-big[data-progress="90"] .circle .mask.full,
.radial-progress-big[data-progress="90"] .circle .fill {
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}
.radial-progress-big[data-progress="90"] .circle .fill.fix {
  -webkit-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  transform: rotate(324deg);
}
.radial-progress-big[data-progress="91"] .circle .mask.full,
.radial-progress-big[data-progress="91"] .circle .fill {
  -webkit-transform: rotate(163.8deg);
  -ms-transform: rotate(163.8deg);
  transform: rotate(163.8deg);
}
.radial-progress-big[data-progress="91"] .circle .fill.fix {
  -webkit-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.radial-progress-big[data-progress="92"] .circle .mask.full,
.radial-progress-big[data-progress="92"] .circle .fill {
  -webkit-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.radial-progress-big[data-progress="92"] .circle .fill.fix {
  -webkit-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.radial-progress-big[data-progress="93"] .circle .mask.full,
.radial-progress-big[data-progress="93"] .circle .fill {
  -webkit-transform: rotate(167.4deg);
  -ms-transform: rotate(167.4deg);
  transform: rotate(167.4deg);
}
.radial-progress-big[data-progress="93"] .circle .fill.fix {
  -webkit-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.radial-progress-big[data-progress="94"] .circle .mask.full,
.radial-progress-big[data-progress="94"] .circle .fill {
  -webkit-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.radial-progress-big[data-progress="94"] .circle .fill.fix {
  -webkit-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.radial-progress-big[data-progress="95"] .circle .mask.full,
.radial-progress-big[data-progress="95"] .circle .fill {
  -webkit-transform: rotate(171deg);
  -ms-transform: rotate(171deg);
  transform: rotate(171deg);
}
.radial-progress-big[data-progress="95"] .circle .fill.fix {
  -webkit-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  transform: rotate(342deg);
}
.radial-progress-big[data-progress="96"] .circle .mask.full,
.radial-progress-big[data-progress="96"] .circle .fill {
  -webkit-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.radial-progress-big[data-progress="96"] .circle .fill.fix {
  -webkit-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.radial-progress-big[data-progress="97"] .circle .mask.full,
.radial-progress-big[data-progress="97"] .circle .fill {
  -webkit-transform: rotate(174.6deg);
  -ms-transform: rotate(174.6deg);
  transform: rotate(174.6deg);
}
.radial-progress-big[data-progress="97"] .circle .fill.fix {
  -webkit-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.radial-progress-big[data-progress="98"] .circle .mask.full,
.radial-progress-big[data-progress="98"] .circle .fill {
  -webkit-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.radial-progress-big[data-progress="98"] .circle .fill.fix {
  -webkit-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.radial-progress-big[data-progress="99"] .circle .mask.full,
.radial-progress-big[data-progress="99"] .circle .fill {
  -webkit-transform: rotate(178.2deg);
  -ms-transform: rotate(178.2deg);
  transform: rotate(178.2deg);
}
.radial-progress-big[data-progress="99"] .circle .fill.fix {
  -webkit-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.radial-progress-big[data-progress="100"] .circle .mask.full,
.radial-progress-big[data-progress="100"] .circle .fill {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.radial-progress-big[data-progress="100"] .circle .fill.fix {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.login-wrapper {
  max-width: 500px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  padding: 50px;
}
.login-wrapper .author_description {
  margin-top: 18px;
  margin-bottom: 40px;
}
.login-wrapper .textinput {
  border-radius: 9999px;
  height: 45px;
}
.login-wrapper img {
  margin: 0 auto 30px;
}
.login-wrapper h4 {
  font-size: 18px;
  font-weight: 500;
  color: #222d39;
}
.login-wrapper h6 {
  font-weight: 400;
  font-size: 14px;
  color: #959cb1;
}
.footer-menu {
  list-style: none;
  margin-top: 10px;
  margin-left: 0;
  padding-left: 0;
}
.footer-menu li {
  display: inline-block;
}
.footer-menu li a {
  font-weight: 500;
  display: inline-block;
  padding: 5px 15px;
  color: #fff;
  opacity: .7;
}
.footer-menu li a:hover {
  opacity: 1;
  text-decoration: none;
}
#mainmenu {
  float: left;
  list-style: none;
  margin-top: 10px;
  margin-left: 50px;
}
#mainmenu li {
  float: left;
}
#mainmenu li a {
  color: #1F2C39;
  display: block;
  padding: 5px 10px;
  margin: 0 5px;
  font-weight: 600;
}
#mainmenu li a:hover {
  opacity: .8;
}
#mainmenu li a.active {
  color: #7231F8;
}
#mainmenu li a.main-blue {
  background: #5E2CED;
  color: #fff;
  border-radius: 5px;
  padding: 5px 25px;
}
#mainmenu li a.main-blue:hover {
  background: #260a76;
}
@media (max-width: 992px) {
  #mainmenu {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
  }
  #mainmenu li {
    display: block;
    float: none;
  }
}
.header-band {
  background: #9462fa;
  background: linear-gradient(169deg, #9462fa 0%, #7231f8 100%);
  padding: 50px 0;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}
.header-band h1 {
  color: #fff;
}
.fancy-form input[type="text"],
.fancy-form textarea,
.fancy-form input[type="password"],
.fancy-form input[type="email"],
.fancy-form input[type="tel"],
.fancy-form input[type="text"],
.fancy-form textarea,
.fancy-form input[type="password"],
.fancy-form input[type="email"] {
  font: 400 15px/60px "Poppins", sans-serif;
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  width: 100%;
  border: 1px solid #fff;
  padding-left: 30px;
}
.fancy-form input[type="text"]:focus,
.fancy-form textarea:focus,
.fancy-form input[type="password"]:focus,
.fancy-form input[type="email"]:focus,
.fancy-form input[type="tel"]:focus {
  border-color: #f2effc;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  outline: none;
}
.fancy-form textarea {
  height: 150px;
}
.resource-box {
  margin-top: 15px;
  transition: all .3s linear;
  display: flex;
  background: #F4F6F9;
  cursor: pointer;
}
.resource-box img {
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.resource-image {
  position: relative;
  display: block;
  min-width: 100px;
  width: 100px;
  border: 5px solid #F4F6F9;
}
.resource-info {
  padding: 15px;
  transition: all .3s linear;
}
.resource-box:hover {
  box-shadow: 0 20px 20px 0 rgba(9, 1, 34, 0.06);
  background: #fff;
}
.resource-date {
  display: block;
}
.resource-title {
  display: block;
  font-size: 16px;
  color: #1F2C39;
}
.resource-title a {
  color: #1F2C39;
}
.resource-detail {
  display: block;
  font-size: 14px;
  line-height: 140%;
}
.latest-resources {
  margin: 0px 0 70px 0;
}
.resource-date {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: #EB2F71;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
.breadcrumb {
  padding: 0;
  margin: 0;
}
.breadcrumb li {
  font-size: 12px;
  color: #fff;
}
.breadcrumb li a {
  color: #fff;
  font-size: 12px;
}
