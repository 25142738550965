.testimonials-slider {
  max-width: 770px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  padding: 50px;

  .author_description{
    margin-top: 18px;
    margin-bottom: 40px;
  }

  h4{
    font-size: 18px;
    font-weight: 500;
    color: #222d39;
  }

  h6{
    font-weight: 400;
    font-size: 14px;
    color: #959cb1;
  }
}

.author_img{
  img{
    width:125px;
    height: 125px;
    border-radius:9999px;
  }
}