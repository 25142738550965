.header-band{
  background: rgb(148,98,250);
  background: linear-gradient(169deg, rgba(148,98,250,1) 0%, rgba(114,49,248,1) 100%);
  padding:50px 0;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;

  h1{
    color: #fff;
  }

}