.wave-info{
  color: #fff;
  padding-left:70px;
  position: relative;
  margin-top:60px;

  &::before{
    content: " ";
    display: block;
    position:absolute;
    left:0;
    top:0 px;
    width:50px;
    height: 50px;
    background:#fff;
    border-radius: 50%;
    border:12px solid #9D6EFD;
  }

  h3{
    color: #fff;
    font-size:30px;
    //font-weight: bold;
    font-weight: 400;
  }
}

.home-wave{
  padding:30px 0;
}