.breadcrumb{
  padding:0;
  margin:0;

  li{
    font-size: 12px;
    color: #fff;
  }
  li a{
    color: #fff;
    font-size:12px;
  }

}